<template>
  <div>
    <breadcrumb-bar
      :title="appTitle"
      :breadcrumbs="breadcrumbs"
    />
    <v-container
      fluid
    >
      <v-row>
        <v-col cols="12">
          <v-form>
            <base-material-card
              color="primary"
              icon="mdi-clipboard-plus"
              inline
              class="px-4 py-2"
            >
              <template v-slot:after-heading>
                <v-card-text class="display-3 font-weight-light">
                  {{ appTitle }}
                </v-card-text>
              </template>
              <v-row>
                <v-col
                  lg="4"
                  md="4"
                  sm="12"
                >
                  <v-text-field
                    v-model="listingData.ProductNumber"
                    dense
                    :label="this.$t('addListingLabelProductNo')"
                    :error-messages="numberErrors"
                    @input="$v.listingData.ProductNumber.$touch()"
                    @blur="$v.listingData.ProductNumber.$touch()"
                  />
                </v-col>
                <v-col
                  lg="4"
                  md="4"
                  sm="12"
                >
                  <v-select
                    v-model="listingData.ProductId"
                    :items="products"
                    item-text="productName"
                    item-value="productId"
                    :label="this.$t('addListingLabelProduct')"
                    dense
                    :error-messages="productIdErrors"
                    @input="$v.listingData.ProductId.$touch()"
                    @blur="$v.listingData.ProductId.$touch()"
                  />
                </v-col>
                <v-col
                  lg="4"
                  md="4"
                  sm="12"
                >
                  <v-select
                    v-model="listingData.ProductType"
                    :items="productTypes"
                    item-text="value"
                    item-value="key"
                    :label="this.$t('addListingLabelProductType')"
                    dense
                    :error-messages="productTypeErrors"
                    @input="$v.listingData.ProductType.$touch()"
                    @blur="$v.listingData.ProductType.$touch()"
                  />
                </v-col>
                <v-col
                  lg="8"
                  md="8"
                  sm="12"
                >
                  <v-text-field
                    v-model="listingData.ProductAlias"
                    dense
                    :label="this.$t('addListingLabelAlias')"
                    :error-messages="aliasErrors"
                    @input="$v.listingData.ProductAlias.$touch()"
                    @blur="$v.listingData.ProductAlias.$touch()"
                  />
                </v-col>
                <v-col
                  lg="4"
                  md="4"
                  sm="12"
                >
                  <span
                    class="pr-4"
                  >
                    {{ $t('addListingLabelRContent') }}
                  </span>
                  <vue-numeric
                    v-model="listingData.RContent"
                    :precision="RContentPrecision"
                    :min="RContentMinValue"
                    :max="RContentMaxValue"
                    align="right"
                    dense
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                  md="6"
                  sm="12"
                >
                  <v-select
                    v-model="listingData.StatusCode"
                    :items="statusCodes"
                    :error-messages="statusCodeErrors"
                    item-text="value"
                    item-value="key"
                    :label="this.$t('addListingLabelStatusCode')"
                    dense
                    @input="$v.listingData.StatusCode.$touch()"
                    @blur="$v.listingData.StatusCode.$touch()"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-data-table
                    :headers="conversionHeaders"
                    :items="listingData.ConversionFactors"
                    item-key="ConversionId"
                    hide-default-footer
                    :items-per-page="listingData.ConversionFactors.length"
                    single-select
                    class="elevation-1"
                  >
                    <template v-slot:top>
                      <v-toolbar
                        flat
                        color="primary darken-2"
                      >
                        <v-toolbar-title
                          class="white--text"
                        >
                          {{ $t('addListingConversionFactors') }}
                        </v-toolbar-title>
                        <v-spacer />
                        <v-btn
                          absolute
                          dark
                          fab
                          top
                          right
                          small
                          color="indigo darken-1"
                          @click="showConversionFactorDialog = true"
                        >
                          <v-icon>
                            mdi-plus
                          </v-icon>
                        </v-btn>
                      </v-toolbar>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </base-material-card>
          </v-form>
        </v-col>
      </v-row>
      <v-row
        class="white"
      >
        <v-col
          cols="12"
        >
          <v-btn
            color="success"
            :disabled="disableSubmit"
            @click="onSubmit"
          >
            {{ submitBtnText }}
          </v-btn>
          <v-btn
            text
            to="/settings/listings"
          >
            {{ $t('buttonClose') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      :value="showConversionFactorDialog"
      persistent
      width="25%"
    >
      <v-card>
        <v-card-title>
          {{ $t('addListing-NewConversionFactor') }}
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="conversionPartner"
            :items="conversionPartners"
            item-text="name"
            item-value="partnerId"
            dense
            :return-object="returnObject"
          />
          <v-select
            v-model="conversionGroup"
            :items="productGroups"
            item-text="productName"
            item-value="productId"
            dense
            :return-object="returnObject"
          />
          <vue-numeric
            v-model="conversionFactor"
            :precision="conversionFactorPrecision"
            align="right"
            dense
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            @click="onAddConversionDialogOk"
          >
            {{ this.$t('buttonOK') }}
          </v-btn>
          <v-btn
            color="error"
            text
            @click="showConversionFactorDialog = false"
          >
            {{ this.$t('buttonClose') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <error-dialog
      dialog-type="error"
      :dialog="showErrorDialog"
      :title="$t('errorTitleProcess')"
      :message="errorMessage"
      @update:dialog="showErrorDialog = false"
    />
    <process-overlay
      :processing="modelProcessing"
    />
  </div>
</template>

<script>
  import { mapFields } from 'vuex-map-fields'
  import { required, maxLength, helpers } from 'vuelidate/lib/validators'
  import productAPI from '@/services/productServices'
  import i18n from '@/i18n'
  const notZero = (value) => !helpers.req(value) || value > 0
  function initialState () {
    return {
      appTitle: i18n.t('titleAddListing'),
      submitBtnText: i18n.t('buttonCreate'),
      breadcrumbs: [
        {
          text: i18n.t('bcHome'),
          disabled: false,
          href: '/dashboard',
        },
        {
          text: i18n.t('bcSettings'),
          disabled: true,
          href: '/settings',
        },
        {
          text: i18n.t('bcListings'),
          disabled: false,
          href: '/settings/listings',
        },
      ],
      showErrorDialog: false,
      showConversionFactorDialog: false,
      errorMessage: '',
      modelProcessing: false,
      listingData: {
        ListingId: 0,
        PartnerId: 0,
        ProductId: 0,
        ProductType: '1',
        ProductAlias: '',
        ProductNumber: '',
        StatusCode: '1',
        RContent: 0,
        ConversionFactors: [],
      },
      returnObject: true,
      products: [],
      conversionPartners: [],
      conversionPartner: null,
      conversionGroup: null,
      conversionFactor: 1,
      conversionFactorPrecision: 3,
      RContentMinValue: 0,
      RContentMaxValue: 100,
      RContentPrecision: 2,
      conversionHeaders: [
        {
          text: i18n.t('colListingPartner'),
          align: 'start',
          value: 'TransactPartnerName',
          sortable: false,
          class: 'info lighten-2 display-1 font-weight-bold',
        },
        {
          text: i18n.t('colListingProduct'),
          align: 'start',
          value: 'GroupName',
          sortable: false,
          class: 'info lighten-2 display-1 font-weight-bold',
        },
        {
          text: i18n.t('colConversionFactor'),
          align: 'end',
          value: 'ConversionFactor',
          sortable: false,
          class: 'info lighten-2 display-1 font-weight-bold',
          width: '15%',
        },
        {
          text: i18n.t('colActions'),
          align: 'start',
          value: 'actions',
          sortable: false,
          class: 'info lighten-2 display-1 font-weight-bold',
          width: '10%',
        },
      ],
    }
  }
  export default {
    Name: 'ProductListing',
    components: {
      ErrorDialog: () => import('@/views/components/core/ErrorDialog'),
      BreadcrumbBar: () => import('@/views/components/core/BreadcrumbBar'),
      ProcessOverlay: () => import('@/views/components/core/Overlay'),
      vueNumeric: () => import('vue-numeric-input'),
    },
    props: {
      listingId: {
        type: String,
        default: '0',
      },
    },
    validations: {
      listingData: {
        ProductId: {
          required,
          notZero,
        },
        ProductType: {
          required,
          notZero,
        },
        ProductAlias: {
          required,
          maxLength: maxLength(100),
        },
        ProductNumber: {
          required,
          maxLength: maxLength(25),
        },
        StatusCode: {
          required,
          notZero,
        },
      },
    },
    data: function () {
      return initialState()
    },
    computed: {
      ...mapFields({
        currentUser: 'currentUser',
        productTypes: 'productTypes',
        statusCodes: 'statusCodes',
        partners: 'partners',
        productGroups: 'productGroups',
      }),
      disableSubmit: function () {
        return this.$v.$invalid
      },
      aliasErrors () {
        const errors = []
        if (!this.$v.listingData.ProductAlias.$dirty) return errors
        !this.$v.listingData.ProductAlias.maxLength && errors.push(this.$t('addListingErrorAliasMaxLength'))
        !this.$v.listingData.ProductAlias.required && errors.push(this.$t('addListingErrorAliasRequired'))
        return errors
      },
      numberErrors () {
        const errors = []
        if (!this.$v.listingData.ProductNumber.$dirty) return errors
        !this.$v.listingData.ProductNumber.maxLength && errors.push(this.$t('addListingErrorProductNoMaxLength'))
        !this.$v.listingData.ProductNumber.required && errors.push(this.$t('addListingErrorProductNoRequired'))
        return errors
      },
      productIdErrors () {
        const errors = []
        if (!this.$v.listingData.ProductId.$dirty) return errors
        !this.$v.listingData.ProductId.required && errors.push(this.$t('addListingErrorProductRequired'))
        !this.$v.listingData.ProductId.notZero && errors.push(this.$t('addListingErrorProductRequired'))
        return errors
      },
      productTypeErrors () {
        const errors = []
        if (!this.$v.listingData.ProductType.$dirty) return errors
        !this.$v.listingData.ProductType.required && errors.push(this.$t('addListingErrorProductTypeRequired'))
        !this.$v.listingData.ProductType.notZero && errors.push(this.$t('addListingErrorProductTypeRequired'))
        return errors
      },
      statusCodeErrors () {
        const errors = []
        if (!this.$v.listingData.StatusCode.$dirty) return errors
        !this.$v.listingData.StatusCode.required && errors.push(this.$t('addProductErrorStatusCodeRequired'))
        !this.$v.listingData.StatusCode.notZero && errors.push(this.$t('addProductErrorStatusCodeRequired'))
        return errors
      },
    },
    created: function () {
      this.modelProcessing = true
      if (this.partners.length === 0) {
        this.$store.dispatch('GET_PARTNERS')
          .then(response => {
            this.updateConversionPartners()
          })
      } else {
        this.updateConversionPartners()
      }
      if (this.productGroups.length === 0) {
        this.$store.dispatch('GET_PRODUCT_GROUPS')
      }
      if (this.productTypes.length === 0) {
        this.$store.dispatch('GET_PRODUCT_TYPES')
      }
      if (this.statusCodes.length === 0) {
        this.$store.dispatch('GET_STATUSCODES')
      }
      productAPI.getAllProducts()
        .then(response => {
          this.products = response.data
        })
      if (this.listingId !== '0') {
        this.submitBtnText = this.$t('buttonSave')
        this.appTitle = this.$t('titleEditListing')
        productAPI.getProductListingById(this.listingId)
          .then(response => {
            this.listingData.ListingId = Number(response.data.listingId)
            this.listingData.ProductId = response.data.productId
            this.listingData.PartnerId = response.data.partnerId
            this.listingData.ProductType = String(response.data.productType)
            this.listingData.ProductAlias = response.data.productAlias
            this.listingData.RContent = response.data.rContent
            this.listingData.StatusCode = String(response.data.statusCode)
            this.listingData.ProductNumber = response.data.productNumber
            // get the conversion factors
            this.listingData.ConversionFactors = []
            for (const item of response.data.conversionFactors) {
              const cfactor = {
                ConversionId: item.conversionId,
                ListingId: this.listingData.ListingId,
                GroupId: item.groupId,
                GroupName: item.groupName,
                TransactPartnerId: item.transactPartnerId,
                TransactPartnerName: item.transactPartnerName,
                ConversionFactor: item.conversionFactor,
                Percentage: item.percentage,
              }
              this.listingData.ConversionFactors.push(cfactor)
            }
            this.modelProcessing = false
          })
          .catch(error => {
            this.errorMessage = error.message
            this.showErrorDialog = true
            this.modelProcessing = false
          })
      } else {
        this.modelProcessing = false
      }
    },
    methods: {
      updateConversionPartners: function () {
        this.conversionPartners = []
        for (const item of this.partners) {
          if (item.partnerId !== Number(this.currentUser.partnerId)) {
            this.conversionPartners.push(item)
          }
        }
      },
      onAddConversionDialogOk: function () {
        if (this.conversionPartner === null || this.conversionGroup === null || this.conversionFactor === 0) {
          return
        }
        let conversionId = 0
        for (const item in this.listingData.ConversionFactors) {
          // get conversion id
          if (item.ConversionId > conversionId) {
            conversionId = item.ConversionId
          }
        }
        conversionId += 1
        const cfactor = {
          ConversionId: conversionId,
          ListingId: this.listingData.ListingId,
          GroupId: this.conversionGroup.productId,
          GroupName: this.conversionGroup.productName,
          TransactPartnerId: this.conversionPartner.partnerId,
          TransactPartnerName: this.conversionPartner.name,
          ConversionFactor: this.conversionFactor,
          Percentage: 100,
        }
        this.listingData.ConversionFactors.push(cfactor)
        this.showConversionFactorDialog = false
      },
      onSubmit: function () {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.listingData.PartnerId = parseInt(this.currentUser.partnerId)
          this.modelProcessing = true
          if (this.listingId !== '0') {
            this.listingData.ListingId = parseInt(this.listingId)
            this.listingData.ProductType = parseInt(this.listingData.ProductType)
            this.listingData.StatusCode = parseInt(this.listingData.StatusCode)
            productAPI.updateProductListing(this.listingData)
              .then(response => {
                this.modelProcessing = false
                this.$router.push('/settings/listings')
              })
              .catch(error => {
                this.showErrorDialog = true
                this.errorMessage = error.message
                this.modelProcessing = false
              })
          } else {
            productAPI.addProductListing(this.listingData)
              .then(response => {
                this.modelProcessing = false
                Object.assign(this.$data, initialState())
                this.$router.push('/settings/listings')
              })
              .catch(error => {
                this.showErrorDialog = true
                this.errorMessage = error.message
                this.modelProcessing = false
              })
          }
        }
      },
    },
  }
</script>
